import { createSlice } from '@reduxjs/toolkit'
import Mock from './Mock.json'

const initialState = Mock

const listaCadastroSlice = createSlice({
  name: 'listaCadastro',
  initialState,
  reducers: {
    adicionarListaCadastro: (state, action) => {
      state.push(action.payload)
    },
  }
})

export const {
  adicionarListaCadastro,
} = listaCadastroSlice.actions
export const listaCadastroReducer = listaCadastroSlice.reducer