import { Box, Button, Container, Stack, Typography } from '@mui/material'
import React from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { CardCadastro } from '../Components/CardCadastro'

export const Home = () => {
    const navigate = useNavigate()
    const listaCadastros = useSelector((state) => state.listaCadastro)

    return (
        <Box sx={{ width: '100%', height: '100%', maxWidth: '800px' }}  >
            <Stack marginTop={'20px'} flexDirection={'row'} alignItems={'center'} justifyContent={'space-between'}>
                <Typography sx={{ padding: '15px' }} variant="h5" component="div" >
                    Pacientes
                </Typography>
                <Button onClick={() => {
                    navigate("/cadastro")
                }}
                    variant="contained"
                >
                    Novo
                </Button>
            </Stack>
            {listaCadastros.map((cad) => <CardCadastro cad={cad} />)}
            <div style={{ height: '30px' }} > </div>
        </Box>
    )
}
