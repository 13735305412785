import { AppBar, Box, Button, createTheme, CssBaseline, IconButton, Stack, ThemeProvider, Toolbar, Typography } from "@mui/material"

import MenuIcon from '@mui/icons-material/Menu'
import React from "react"
import { AppRoutes } from "./routes"

const CorPrincipal = "#150f69"

const theme = createTheme({
    components: {
        MuiButton: {
            styleOverrides: {
                // Name of the slot
                root: {
                    backgroundColor: CorPrincipal
                },
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                // Name of the slot
                root: {
                    "&.Mui-focused": {
                        "& .MuiOutlinedInput-notchedOutline": {
                            borderColor: CorPrincipal,
                        },
                    }
                },
            },
        },
        MuiStepIcon: {
            styleOverrides: {
                // Name of the slot
                root: {
                    "&.Mui-active": {
                        color: CorPrincipal
                    },
                    "&.Mui-completed": {
                        color: CorPrincipal
                    }
                },
            },
        },
        MuiRadio: {
            styleOverrides: {
                // Name of the slot
                root: {
                    "&.Mui-checked": {
                        color: CorPrincipal
                    },
                },
            },
        },
        MuiCheckbox: {
            styleOverrides: {
                // Name of the slot
                root: {
                    "&.Mui-checked": {
                        color: CorPrincipal
                    },
                },
            },
        },
        MuiToolbar: {
            styleOverrides: {
                root: {
                    backgroundColor: "#fff",
                    color: "#000000",
                    minHeight: "40px",
                    height: "40px",
                    '@media (min-width: 600px)': {
                        minHeight: "40px"
                    }
                },
            },
        },

    },
})

export default function Main() {
    return (
        <ThemeProvider theme={theme}>
            <Box sx={{ height: '100dvh', display: 'flex', flexDirection: 'column' }}>
                <AppBar position="relative"  >
                    <CssBaseline enableColorScheme />
                    <Toolbar sx={{ height: "1px", minHeight: "1px" }}>
                        <IconButton
                            size="small"
                            edge="start"
                            color="inherit"
                            aria-label="menu"
                            sx={{ mr: 2 }}
                        >
                            <MenuIcon />
                        </IconButton>
                        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                            VisonCare
                        </Typography>
                    </Toolbar>
                </AppBar>
                <Stack key='ContainerPrincipal' sx={{ flex: 1, flexDirection: 'row', alignItems: 'center', justifyContent: 'center', overflowX: 'hidden', overflowY: 'auto', scrollbarWidth: 'thin' }}>
                    <AppRoutes />
                </Stack >
            </Box>
        </ThemeProvider>
    )
}
