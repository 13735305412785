
import Grid from '@mui/material/Grid2';
import { styled } from '@mui/system';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import { Checkbox, Radio, RadioGroup } from '@mui/material';
import { useDispatch } from 'react-redux';

const FormGrid = styled(Grid)(() => ({
    display: 'flex',
    flexDirection: 'column',
}));

export default function PerguntaRadio(props) {
    const dispatch = useDispatch()

    const opcoes = props.opcoes ? props.opcoes : ["Sim", "Não"]
    return (
        <FormGrid size={{ xs: 12 }}>
            <FormLabel   >
                {props.pergunta}
            </FormLabel>
            <Grid display={'flex'} size={{ xs: 12 }}>
                <RadioGroup
                    row
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue="female"
                    name="radio-buttons-group"
                    value={props.valor}
                    onChange={(event) => {
                        if (props.setDados) {
                            dispatch(props.setDados(event.target.value))
                        }
                    }}
                >
                    {opcoes.map((x) => {
                        return (
                            <FormControlLabel
                                key={x}
                                value={x}
                                control={<Radio />}
                                label={x}
                                labelPlacement={"end"}
                            />
                        )
                    })}
                    {props.outros && (
                        <FormControlLabel
                            value="Outros"
                            control={<Radio />}
                            label="Outros"
                            labelPlacement={"end"}
                        />
                    )}
                </RadioGroup>

            </Grid>

        </FormGrid>
    )
}
