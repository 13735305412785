import { Stack, styled, Typography } from "@mui/material"

const StackSty = styled(Stack)({
    "&:hover": {
        backgroundColor: '#f2f2f2'
    }
});

export const CardCadastro = ({ cad }) => {
    return (
        <StackSty sx={{
            boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.4)", height: '40px',
            paddingY: 1,
            paddingX: 3,
            marginY: 1
        }}>
            <Typography>{cad.Nome}</Typography>
        </StackSty>
    )
}