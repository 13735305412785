import { Routes, Route, Navigate } from "react-router-dom"
import { ProtectedRoute } from "./ProtectedRoute"
import { Login } from "../screen/Login"
import { Home } from "../screen/Home"
import { Cadastro } from "../screen/Cadastro"

export const AppRoutes = () => {
    return (
        <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/home" element={<ProtectedRoute> <Home /></ProtectedRoute>} />
            <Route path="/cadastro" element={<ProtectedRoute> <Cadastro /></ProtectedRoute>} />
            <Route path="*" element={<ProtectedRoute><Navigate to="/home" /></ProtectedRoute>} />
        </Routes>
    )

}