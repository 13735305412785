import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  Nome: "",
  Escolaridade: "",
  NomeEscola: "",
  Profissao: "",
  Endereco: "",
  CEP: "",
  LocalNascimento: "",
  NomePai: "",
  ProfissaoPai: "",
  NomeMae: "",
  ProfissaoMae: "",
  UsaOculos: "",
  MultiFocal: "",
  UsaLente: "",
  FechaOlho: "",
  Tercol: "",
  VisaoEmbacada: "",
  OlhoSeco: "",
  Ardencia: "",
  Epifora: "",
  PontoBrilhante: "",
  Vermelhidao: "",
  Prirido: "",
  Has: "",
  Problemafigado: "",
  Diabetes: "",
  Gripe: "",
  Cefaleia: "",
  Conjuntivite: "",
  Fotofobia: "",
  Trauma: "",
  TomaMedicamento: "",
  TeveOtite: "",
  FamiliaDoenca: "",
  RecusaAlimento: "",
  ComidaPastosa: "",
  Decidida: "",
  CorPreferida: "",
  TempoLivre: "",
  AtividadeFisica: "",
  TempoFinalSemana: "",
  Descalco: "",
  ArLivre: "",
  Contato: "",
  GostaDesenhar: "",
  Cantar: "",
  DificuldadeParaVer: {
    Longe: false,
    Perto: false,
    Embacado: false,
    Diplopia: false,
    Tontura: false,
    Nausea: false,
    Prurido: false,
    Edifora: false,
    Vermelhidao: false,
    Ardencia: false,
    Halos: false,
    Cegueira: false,
    PVC: false,
  }
}

const counterSlice = createSlice({
  name: 'counter',
  initialState,
  reducers: {
    setCadastroCompleto: (state, action) => {
      state = action.payload
    },
    setNome: (state, action) => {
      state.Nome = action.payload
    },
    setEscolaridade: (state, action) => {
      state.Escolaridade = action.payload
    },
    setNomeEscola: (state, action) => {
      state.NomeEscola = action.payload
    },
    setProfissao: (state, action) => {
      state.Profissao = action.payload
    },
    setEndereco: (state, action) => {
      state.Endereco = action.payload
    },
    setCEP: (state, action) => {
      state.CEP = action.payload
    },
    setLocalNascimento: (state, action) => {
      state.LocalNascimento = action.payload
    },
    setNomePai: (state, action) => {
      state.NomePai = action.payload
    },
    setProfissaoPai: (state, action) => {
      state.ProfissaoPai = action.payload
    },
    setNomeMae: (state, action) => {
      state.NomeMae = action.payload
    },
    setProfissaoMae: (state, action) => {
      state.ProfissaoMae = action.payload
    },
    setUsaOculos: (state, action) => {
      state.UsaOculos = action.payload
    },
    setMultiFocal: (state, action) => {
      state.MultiFocal = action.payload
    },
    setUsaLente: (state, action) => {
      state.UsaLente = action.payload
    },
    setFechaOlho: (state, action) => {
      state.FechaOlho = action.payload
    },
    setTercol: (state, action) => {
      state.Tercol = action.payload
    },
    setVisaoEmbacada: (state, action) => {
      state.VisaoEmbacada = action.payload
    },
    setOlhoSeco: (state, action) => {
      state.OlhoSeco = action.payload
    },
    setArdencia: (state, action) => {
      state.Ardencia = action.payload
    },
    setEpifora: (state, action) => {
      state.Epifora = action.payload
    },
    setPontoBrilhante: (state, action) => {
      state.PontoBrilhante = action.payload
    },
    setVermelhidao: (state, action) => {
      state.Vermelhidao = action.payload
    },
    setPrirido: (state, action) => {
      state.Prirido = action.payload
    },
    setHas: (state, action) => {
      state.Has = action.payload
    },
    setProblemafigado: (state, action) => {
      state.Problemafigado = action.payload
    },
    setDiabetes: (state, action) => {
      state.Diabetes = action.payload
    },
    setGripe: (state, action) => {
      state.Gripe = action.payload
    },
    setCefaleia: (state, action) => {
      state.Cefaleia = action.payload
    },
    setConjuntivite: (state, action) => {
      state.Conjuntivite = action.payload
    },
    setFotofobia: (state, action) => {
      state.Fotofobia = action.payload
    },
    setTrauma: (state, action) => {
      state.Trauma = action.payload
    },
    setTomaMedicamento: (state, action) => {
      state.TomaMedicamento = action.payload
    },
    setTeveOtite: (state, action) => {
      state.TeveOtite = action.payload
    },
    setFamiliaDoenca: (state, action) => {
      state.FamiliaDoenca = action.payload
    },
    setRecusaAlimento: (state, action) => {
      state.RecusaAlimento = action.payload
    },
    setComidaPastosa: (state, action) => {
      state.ComidaPastosa = action.payload
    },
    setDecidida: (state, action) => {
      state.Decidida = action.payload
    },
    setCorPreferida: (state, action) => {
      state.CorPreferida = action.payload
    },
    setTempoLivre: (state, action) => {
      state.TempoLivre = action.payload
    },
    setAtividadeFisica: (state, action) => {
      state.AtividadeFisica = action.payload
    },
    setTempoFinalSemana: (state, action) => {
      state.TempoFinalSemana = action.payload
    },
    setDescalco: (state, action) => {
      state.Descalco = action.payload
    },
    setArLivre: (state, action) => {
      state.ArLivre = action.payload
    },
    setContato: (state, action) => {
      state.Contato = action.payload
    },
    setGostaDesenhar: (state, action) => {
      state.GostaDesenhar = action.payload
    },
    setCantar: (state, action) => {
      state.Cantar = action.payload
    },
    setDificuldadeLonge: (state, action) => {
      state.DificuldadeParaVer.Longe = action.payload
    },
    setDificuldadePerto: (state, action) => {
      state.DificuldadeParaVer.Perto = action.payload
    },
    setDificuldadeEmbacado: (state, action) => {
      state.DificuldadeParaVer.Embacado = action.payload
    },
    setDificuldadeDiplopia: (state, action) => {
      state.DificuldadeParaVer.Diplopia = action.payload
    },
    setDificuldadeTontura: (state, action) => {
      state.DificuldadeParaVer.Tontura = action.payload
    },
    setDificuldadeNausea: (state, action) => {
      state.DificuldadeParaVer.Nausea = action.payload
    },
    setDificuldadePrurido: (state, action) => {
      state.DificuldadeParaVer.Prurido = action.payload
    },
    setDificuldadeEdifora: (state, action) => {
      state.DificuldadeParaVer.Edifora = action.payload
    },
    setDificuldadeVermelhidao: (state, action) => {
      state.DificuldadeParaVer.Vermelhidao = action.payload
    },
    setDificuldadeArdencia: (state, action) => {
      state.DificuldadeParaVer.Ardencia = action.payload
    },
    setDificuldadeHalos: (state, action) => {
      state.DificuldadeParaVer.Halos = action.payload
    },
    setDificuldadeCegueira: (state, action) => {
      state.DificuldadeParaVer.Cegueira = action.payload
    },
    setDificuldadePVC: (state, action) => {
      state.DificuldadeParaVer.PVC = action.payload
    },
  }
})

export const {
  setCadastroCompleto,
  setNome,
  setEscolaridade,
  setNomeEscola,
  setProfissao,
  setEndereco,
  setCEP,
  setLocalNascimento,
  setNomePai,
  setProfissaoPai,
  setNomeMae,
  setProfissaoMae,
  setUsaOculos,
  setMultiFocal,
  setUsaLente,
  setFechaOlho,
  setTercol,
  setVisaoEmbacada,
  setOlhoSeco,
  setArdencia,
  setEpifora,
  setPontoBrilhante,
  setVermelhidao,
  setPrirido,
  setHas,
  setProblemafigado,
  setDiabetes,
  setGripe,
  setCefaleia,
  setConjuntivite,
  setFotofobia,
  setTrauma,
  setTomaMedicamento,
  setTeveOtite,
  setFamiliaDoenca,
  setRecusaAlimento,
  setComidaPastosa,
  setDecidida,
  setCorPreferida,
  setTempoLivre,
  setAtividadeFisica,
  setTempoFinalSemana,
  setDescalco,
  setArLivre,
  setContato,
  setGostaDesenhar,
  setCantar,
  setDificuldadeLonge,
  setDificuldadePerto,
  setDificuldadeEmbacado,
  setDificuldadeDiplopia,
  setDificuldadeTontura,
  setDificuldadeNausea,
  setDificuldadePrurido,
  setDificuldadeEdifora,
  setDificuldadeVermelhidao,
  setDificuldadeArdencia,
  setDificuldadeHalos,
  setDificuldadeCegueira,
  setDificuldadePVC,
} = counterSlice.actions
export const cadastroReducer = counterSlice.reducer