import { useDispatch, useSelector } from 'react-redux';
import {
    Navigate,
} from 'react-router-dom';

export const ProtectedRoute = ({ children }) => {

    // const userLogged = useSelector((state) => state.login.userLogged)
    const dispatch = useDispatch()
    const userLogged = true
    if (!userLogged) {
        return <Navigate to="/login" replace />;
    }

    return children;
};