import { AppBar, Box, Button, createTheme, CssBaseline, IconButton, Paper, Stack, Step, StepLabel, Stepper, ThemeProvider, Toolbar, Typography } from "@mui/material"

import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormLabel from '@mui/material/FormLabel'
import Grid from '@mui/material/Grid2'
import OutlinedInput from '@mui/material/OutlinedInput'
import { styled } from '@mui/system'
import VisibilityIcon from '@mui/icons-material/Visibility'
import React from "react"
import ChevronLeftRoundedIcon from '@mui/icons-material/ChevronLeftRounded'
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded'
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import PerguntaCheck from "../Components/PerguntaCheck"
import PerguntaRadio from "../Components/PerguntaRadio"
import PerguntaInput from "../Components/PerguntaInput"
import { useDispatch, useSelector } from "react-redux"
import {
    setCEP,
    setEndereco,
    setEscolaridade,
    setLocalNascimento,
    setNome,
    setNomeEscola,
    setNomeMae,
    setNomePai,
    setProfissao,
    setProfissaoMae,
    setProfissaoPai,
    setUsaOculos,
    setMultiFocal,
    setUsaLente,
    setFechaOlho,
    setTercol,
    setVisaoEmbacada,
    setOlhoSeco,
    setArdencia,
    setEpifora,
    setPontoBrilhante,
    setVermelhidao,
    setPrirido,
    setHas,
    setProblemafigado,
    setDiabetes,
    setGripe,
    setCefaleia,
    setConjuntivite,
    setFotofobia,
    setTrauma,
    setTomaMedicamento,
    setTeveOtite,
    setFamiliaDoenca,
    setRecusaAlimento,
    setComidaPastosa,
    setDecidida,
    setCorPreferida,
    setTempoLivre,
    setAtividadeFisica,
    setTempoFinalSemana,
    setDescalco,
    setArLivre,
    setContato,
    setGostaDesenhar,
    setCantar,
    setDificuldadeLonge,
    setDificuldadePerto,
    setDificuldadeEmbacado,
    setDificuldadeDiplopia,
    setDificuldadeTontura,
    setDificuldadeNausea,
    setDificuldadePrurido,
    setDificuldadeEdifora,
    setDificuldadeVermelhidao,
    setDificuldadeArdencia,
    setDificuldadeHalos,
    setDificuldadeCegueira,
    setDificuldadePVC,
} from '../cadastro/cadastro-slice'
import { Padding } from "@mui/icons-material"
import { adicionarListaCadastro } from "../cadastro/listaCadastro-slice"
import { useNavigate } from "react-router-dom"

const FormGrid = styled(Grid)(() => ({
    display: 'flex',
    flexDirection: 'column',
}))

const DatePickerCustom = styled(DatePicker)({ '& input': { padding: '8px', }, })

function validacaoEtapa1(Dados, Validacao, setValidacao) {
    let val = {
        Nome: false,
        Escolaridade: false,
        NomeEscola: false,
        Profissao: false,
        Endereco: false,
        CEP: false,
        LocalNascimento: false,
        NomePai: false,
        ProfissaoPai: false,
        NomeMae: false,
        ProfissaoMae: false,
    }
    let SemErro = true
    if (Dados.Nome == '') {
        val.Nome = true
        SemErro = false
    } else { val.Nome = false }
    if (Dados.Escolaridade == '') { val.Escolaridade = true } else { val.Escolaridade = false }
    if (Dados.NomeEscola == '') { val.NomeEscola = true } else { val.NomeEscola = false }
    if (Dados.Profissao == '') { val.Profissao = true } else { val.Profissao = false }
    if (Dados.Endereco == '') { val.Endereco = true } else { val.Endereco = false }
    if (Dados.CEP == '') { val.CEP = true } else { val.CEP = false }
    if (Dados.LocalNascimento == '') { val.LocalNascimento = true } else { val.LocalNascimento = false }
    if (Dados.NomePai == '') { val.NomePai = true } else { val.NomePai = false }
    if (Dados.ProfissaoPai == '') { val.ProfissaoPai = true } else { val.ProfissaoPai = false }
    if (Dados.NomeMae == '') { val.NomeMae = true } else { val.NomeMae = false }
    if (Dados.ProfissaoMae == '') { val.ProfissaoMae = true } else { val.ProfissaoMae = false }
    setValidacao({ ...Validacao, ...val })
    return SemErro
}

function validacao(passoAtivo, Dados, Validacao, setValidacao) {
    if (passoAtivo == 0) {
        return validacaoEtapa1(Dados, Validacao, setValidacao)
    }
    else {
        return true
    }
}


export function Cadastro() {
    const navigate = useNavigate()
    const Dados = useSelector((state) => state.cadastro)
    const dispatch = useDispatch()
    const [passoAtivo, setPassoAtivo] = React.useState(0)
    const [Validacao, setValidacao] = React.useState({
        Nome: false,
        Escolaridade: false,
        NomeEscola: false,
        Profissao: false,
        Endereco: false,
        CEP: false,
        LocalNascimento: false,
        NomePai: false,
        ProfissaoPai: false,
        NomeMae: false,
        ProfissaoMae: false,
    })
    const handleNext = () => {
        if (validacao(passoAtivo, Dados, Validacao, setValidacao)) {
            setPassoAtivo(passoAtivo + 1)
        }
    }
    const handleBack = () => {
        setPassoAtivo(passoAtivo - 1)
    }
    const handleFinalizar = () => {
        dispatch(adicionarListaCadastro(Dados))
        navigate("/home")
    }

    return (<>
        <Stack key='ContainerEsquedo' sx={{
            boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.4)",
            flex: 1, height: '100%', width: '100%', overflowY: 'auto', padding: '30px', justifyContent: 'center'
        }}>
            <Stepper
                id="desktop-stepper"
                passoAtivo={passoAtivo}
                orientation="vertical"
            >
                {passos.map((label) => (
                    <Step
                        sx={{ ':first-child': { pl: 0 }, ':last-child': { pr: 0 } }}
                        key={label}
                    >
                        <StepLabel>{label}</StepLabel>
                    </Step>
                ))}
            </Stepper>
        </Stack>
        <Stack key='ContainerDireito' sx={{ flex: 5, height: '100%', width: '100%' }}>
            <Stack style={{ flex: 1, alignItems: 'center', overflowX: 'hidden', overflowY: 'auto', scrollbarWidth: 'thin', padding: '30px' }}>
                <Stack style={{ maxWidth: '800px' }}>
                    {obterPassoAtual(passoAtivo, Validacao)}
                </Stack>
            </Stack>
            <Box
                sx={[
                    {
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'end',
                        padding: '10px'
                    },
                    passoAtivo !== 0
                        ? { justifyContent: 'space-between' }
                        : { justifyContent: 'flex-end' },
                ]}
            >
                {passoAtivo !== 0 && (
                    <Button
                        variant="contained"
                        startIcon={<ChevronLeftRoundedIcon />}
                        onClick={handleBack}
                    >
                        Anterior
                    </Button>
                )}
                {passoAtivo === passos.length - 1 ?
                    <Button
                        variant="contained"
                        endIcon={<ChevronRightRoundedIcon />}
                        onClick={handleFinalizar}
                        sx={{ width: { xs: '100%', sm: 'fit-content' } }}
                    >
                        Finalizar
                    </Button> :
                    <Button
                        variant="contained"
                        endIcon={<ChevronRightRoundedIcon />}
                        onClick={handleNext}
                        sx={{ width: { xs: '100%', sm: 'fit-content' } }}
                    >
                        Próximo
                    </Button>}
            </Box>
        </Stack>
    </>
    )
}

const passos = ['Cadastro', 'Sobre a Visão', 'Doenças', 'Pessoal']

function obterPassoAtual(passos, Validacao) {
    switch (passos) {
        case 0:
            return <DadosPessoais Validacao={Validacao} />
        case 1:
            return <DadosVisao Validacao={Validacao} />
        case 2:
            return <DadosDoenca Validacao={Validacao} />
        case 3:
            return <DadosPessoal Validacao={Validacao} />
        default:
            throw new Error('Passo Desconhecido')
    }
}

function DadosPessoais(props) {
    const Dados = useSelector((state) => state.cadastro)
    return (
        <Grid container spacing={2}>
            <PerguntaInput
                setDados={setNome} valor={Dados.Nome} obrigatorio erro={props.Validacao.Nome}
                pergunta="Nome Completo"
            />
            <PerguntaInput
                xs={6}
                setDados={setEscolaridade} valor={Dados.Escolaridade} erro={props.Validacao.Escolaridade}
                pergunta="Escolaridade"
            />
            <PerguntaInput
                xs={6}
                setDados={setNomeEscola} valor={Dados.NomeEscola} erro={props.Validacao.NomeEscola}
                pergunta="Nome da Escola"
            />
            <PerguntaInput
                setDados={setProfissao} valor={Dados.Profissao} erro={props.Validacao.Profissao}
                pergunta="Profissão"
            />
            <PerguntaInput
                setDados={setEndereco} valor={Dados.Endereco} erro={props.Validacao.Endereco}
                pergunta="Endereço"
            />
            <PerguntaInput
                xs={6}
                setDados={setCEP} valor={Dados.CEP} erro={props.Validacao.CEP}
                pergunta="CEP"
            />
            <FormGrid size={{ xs: 6 }}>
                <FormLabel htmlFor="Idade"  >
                    Data de Nascimento
                </FormLabel>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePickerCustom />
                </LocalizationProvider>
            </FormGrid>
            <PerguntaInput
                setDados={setLocalNascimento} valor={Dados.LocalNascimento} erro={props.Validacao.LocalNascimento}
                pergunta="Local Nascimento"
            />
            <PerguntaInput
                setDados={setNomePai} valor={Dados.NomePai} erro={props.Validacao.NomePai}
                pergunta="Nome da Pai"
            />
            <PerguntaInput
                setDados={setProfissaoPai} valor={Dados.ProfissaoPai} erro={props.Validacao.ProfissaoPai}
                pergunta="Profissão da Pai"
            />
            <PerguntaInput
                setDados={setNomeMae} valor={Dados.NomeMae} erro={props.Validacao.NomeMae}
                pergunta="Nome da Mãe"
            />
            <PerguntaInput
                setDados={setProfissaoMae} valor={Dados.ProfissaoMae} erro={props.Validacao.ProfissaoMae}
                pergunta="Profissão da Mãe"
            />
        </Grid>
    )
}
function DadosVisao() {
    const Dados = useSelector((state) => state.cadastro)
    return (
        <Grid container spacing={2}   >
            <PerguntaRadio
                setDados={setUsaOculos} valor={Dados.UsaOculos}
                pergunta="Usa óculos?"
                opcoes={["Sim", "Perdeu", "Quebrou", "Nunca usou"]}
            />
            <PerguntaRadio
                setDados={setMultiFocal} valor={Dados.MultiFocal}
                pergunta="Multifocal?"
                opcoes={["Não", "Sim", "PPC"]}
                outros
            />
            <PerguntaRadio
                setDados={setUsaLente} valor={Dados.UsaLente}
                pergunta="Usa lentes de contato?"
            />
            <PerguntaCheck
                pergunta="Dificuldade para ver?"
                opcoes={[
                    { texto: "Longe", setDados: setDificuldadeLonge, valor: Dados.DificuldadeParaVer.Longe },
                    { texto: "Perto", setDados: setDificuldadePerto, valor: Dados.DificuldadeParaVer.Perto },
                    { texto: "Embaçado ou Borrado", setDados: setDificuldadeEmbacado, valor: Dados.DificuldadeParaVer.Embacado },
                    { texto: "Diplopia", setDados: setDificuldadeDiplopia, valor: Dados.DificuldadeParaVer.Diplopia },
                    { texto: "Tontura", setDados: setDificuldadeTontura, valor: Dados.DificuldadeParaVer.Tontura },
                    { texto: "Náusea", setDados: setDificuldadeNausea, valor: Dados.DificuldadeParaVer.Nausea },
                    { texto: "Prurido(Coceira)", setDados: setDificuldadePrurido, valor: Dados.DificuldadeParaVer.Prurido },
                    { texto: "Edífora(Lacrimejamento)", setDados: setDificuldadeEdifora, valor: Dados.DificuldadeParaVer.Edifora },
                    { texto: "Vermelhidão", setDados: setDificuldadeVermelhidao, valor: Dados.DificuldadeParaVer.Vermelhidao },
                    { texto: "Ardência", setDados: setDificuldadeArdencia, valor: Dados.DificuldadeParaVer.Ardencia },
                    { texto: "Halos? Coloridos ao redor da Luz", setDados: setDificuldadeHalos, valor: Dados.DificuldadeParaVer.Halos },
                    { texto: "Cegueira noturna", setDados: setDificuldadeCegueira, valor: Dados.DificuldadeParaVer.Cegueira },
                    { texto: "PVC (Posição Viciosa de cabeça", setDados: setDificuldadePVC, valor: Dados.DificuldadeParaVer.PVC },
                ]}
            />
            <PerguntaInput
                setDados={setFechaOlho} valor={Dados.FechaOlho}
                pergunta="Fecha um dos olhos para enxergar melhor? Se sim, qual dos olhos?"
            />
            <PerguntaRadio
                setDados={setTercol} valor={Dados.Tercol}
                pergunta="Terçol?"
            />
            <PerguntaRadio
                setDados={setVisaoEmbacada} valor={Dados.VisaoEmbacada}
                pergunta="Visão embaçada?"
            />
            <PerguntaRadio
                setDados={setOlhoSeco} valor={Dados.OlhoSeco}
                pergunta="Sente o olho seco?"
            />
            <PerguntaRadio
                setDados={setArdencia} valor={Dados.Ardencia}
                pergunta="Ardência"
            />
            <PerguntaRadio
                setDados={setEpifora} valor={Dados.Epifora}
                pergunta="Epífora (lacrimejamento)"
            />
            <PerguntaRadio
                setDados={setPontoBrilhante} valor={Dados.PontoBrilhante}
                pergunta="Enxerga pontos brilhantes?"
            />
            <PerguntaRadio
                setDados={setVermelhidao} valor={Dados.Vermelhidao}
                pergunta="Vermelhidão"
            />
            <PerguntaRadio
                setDados={setPrirido} valor={Dados.Prirido}
                pergunta="Prirido (Coceira)"
            />
        </Grid>
    )
}
function DadosDoenca() {
    const Dados = useSelector((state) => state.cadastro)
    return (
        <Grid container spacing={2}   >
            <PerguntaRadio
                setDados={setHas} valor={Dados.Has}
                pergunta="HAS (Hipertensão Arterial Sistêmica)"
                opcoes={["Não"]}
                outros
            />
            <PerguntaRadio
                setDados={setProblemafigado} valor={Dados.Problemafigado}
                pergunta="Problema no fígado?"
                outros
            />
            <PerguntaRadio
                setDados={setDiabetes} valor={Dados.Diabetes}
                pergunta="DIA (Diabetes)"
                opcoes={["Não"]}
                outros
            />
            <PerguntaRadio
                setDados={setGripe} valor={Dados.Gripe}
                pergunta="Gripe (ultimos 6 meses)?"
                outros
            />
            <PerguntaRadio
                setDados={setCefaleia} valor={Dados.Cefaleia}
                pergunta="Cefaléia"
                opcoes={["Frontal", "Temporal", "Parietal", "Cintamento", "Migraine"]}
                outros
            />
            <PerguntaRadio
                setDados={setConjuntivite} valor={Dados.Conjuntivite}
                pergunta="Conjuntivite (Ultimos 6 meses)?"
                outros
            />
            <PerguntaRadio
                setDados={setFotofobia} valor={Dados.Fotofobia}
                pergunta="Fotofobia"
                opcoes={["Não", "Sim", "Não usar lente anti-reflexo"]}
                outros
            />
            <PerguntaRadio
                setDados={setTrauma} valor={Dados.Trauma}
                pergunta="Trauma ou cirurgia ocular?"
                outros
            />
            <PerguntaRadio
                setDados={setTomaMedicamento} valor={Dados.TomaMedicamento}
                pergunta="Toma algum medicamento de uso contínuo?"
                outros
            />
            <PerguntaInput
                setDados={setTeveOtite} valor={Dados.TeveOtite}
                pergunta="Teve otite ou infeção ou internação grave? Quando foi? Como tratou? Quando tempo usou o medicamento?"
            />
            <PerguntaRadio
                setDados={setFamiliaDoenca} valor={Dados.FamiliaDoenca}
                pergunta="Alguém na família perdeu a visão, glaucoma, catarata, DMRI (Degeneração Macular Relacionada à Idade)"
            />
        </Grid>
    )
}
function DadosPessoal() {
    const Dados = useSelector((state) => state.cadastro)
    return (
        <Grid container spacing={2}>
            <Typography variant="h5" gutterBottom>Alimentação</Typography>
            <PerguntaInput
                setDados={setRecusaAlimento} valor={Dados.RecusaAlimento}
                pergunta="Recusa algum alimento que não come de jeito nenhum?"
            />
            <PerguntaRadio
                setDados={setComidaPastosa} valor={Dados.ComidaPastosa}
                pergunta="Come comida pastosa, tipo purê?"
            />
            <Typography variant="h5" gutterBottom>Pessoal</Typography>
            <PerguntaRadio
                setDados={setDecidida} valor={Dados.Decidida}
                pergunta="Pessoa decidida?"
            />
            <PerguntaInput
                setDados={setCorPreferida} valor={Dados.CorPreferida}
                pergunta="Qual a cor que o paciente mais gosta?"
            />
            {/* <PerguntaRadio
                setDad2os={setUsaOculos} valor={Dados.UsaOculos}
                pergunta="Quais desses alimentos costuma consumir e com qual frequência?"
                opcoes={["PENDENTE"]}
            /> */}
            <PerguntaInput
                setDados={setTempoLivre} valor={Dados.TempoLivre}
                pergunta="O que o paciente adora fazer no tempo livre?"
            />
            <PerguntaInput
                setDados={setAtividadeFisica} valor={Dados.AtividadeFisica}
                pergunta="Pratica alguma atividade física?"
            />
            <PerguntaInput
                setDados={setTempoFinalSemana} valor={Dados.TempoFinalSemana}
                pergunta="Como o paciente costuma passar o tempo no final de semana?"
            />
            <PerguntaRadio
                setDados={setDescalco} valor={Dados.Descalco}
                pergunta="Fica descalço e anda na terra?"
            />
            <PerguntaInput
                setDados={setArLivre} valor={Dados.ArLivre}
                pergunta="Com que frequência fica exposto em ambientes ao ar livre? Quais atividades costuma fazer ao ar livre?"
            />
            <PerguntaRadio
                setDados={setContato} valor={Dados.Contato}
                pergunta="Gosta de estar em contato com a natureza?"
            />
            <PerguntaRadio
                setDados={setGostaDesenhar} valor={Dados.GostaDesenhar}
                pergunta="Gosta de desenhar?"
            />
            <PerguntaRadio
                setDados={setCantar} valor={Dados.Cantar}
                pergunta="Gosta de cantar?"
            />
        </Grid>
    )
}
