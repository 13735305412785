
import Grid from '@mui/material/Grid2';
import { styled } from '@mui/system';
import FormLabel from '@mui/material/FormLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import { useDispatch } from 'react-redux';

const FormGrid = styled(Grid)(() => ({
    display: 'flex',
    flexDirection: 'column',
}));

export default function PerguntaInput(props) {
    const dispatch = useDispatch()

    return (
        <FormGrid size={props.xs ? { xs: props.xs } : { xs: 12 }}>
            <FormLabel>
                {props.pergunta} {(props.obrigatorio)&&('*')}
            </FormLabel>
            <OutlinedInput
                id="outlined-error"
                defaultValue={props.valor}
                size="small"
                onChange={(event) => {
                    if (props.setDados) {
                        dispatch(props.setDados(event.target.value))
                    }
                }}
            />
            {props.obrigatorio && props.erro && (<div style={{ color: 'red' }}>Campo obrigatório</div>)}
        </FormGrid>
    )
}
