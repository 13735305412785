
import Grid from '@mui/material/Grid2';
import { styled } from '@mui/system';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import { Checkbox } from '@mui/material';
import { useDispatch } from 'react-redux';

const FormGrid = styled(Grid)(() => ({
    display: 'flex',
    flexDirection: 'column',
}));

export default function PerguntaCheck(props) {
    const dispatch = useDispatch()
    const opcoes = props.opcoes ? props.opcoes : ["Sim", "Não"]

    return (
        <FormGrid size={{ xs: 12 }}>
            <FormLabel   >
                {props.pergunta}
            </FormLabel>
            <Grid display={'flex'} flexDirection={'column'} size={{ xs: 12 }}>
                {opcoes.map((x) => {
                    return (
                        <FormControlLabel
                            key={x.texto}
                            value={x.texto}
                            control={<Checkbox
                                checked={x.valor}
                                onChange={(event) => {
                                    dispatch(x.setDados(event.target.checked));
                                }} />}
                            label={x.texto}
                            labelPlacement={"end"}
                        />
                    )
                })}
                {props.outros && (
                    <FormControlLabel
                        value="Outros"
                        control={<Checkbox />}
                        label="Outros"
                        labelPlacement={"end"}
                    />
                )}
            </Grid>
        </FormGrid>
    )
}
